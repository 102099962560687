<template>
  <div class="b-modal">
    <div id="stl_cont" class="stl-preview"></div>
  </div>
</template>
<script>
import ElementIcon from "@/components/elements/Icon.vue";
import { BLink, BRow, BCol, BImg, BPagination } from "bootstrap-vue";
import { StlViewer } from "@/libs/3dviewer/stl_viewer.min.js";
import NProgress from "nprogress";
import { mustGetEnv } from "@/utils/env";

export default {
  components: {
    ElementIcon,
    BRow,
    BCol,
    BLink,
    BImg,
    BPagination,
  },
  props: {
    jaw: {
      type: String,
      default: "",
    },
  },
  methods: {
    uploadDone: function () {
      NProgress.done();
      this.loadingDone = true;
    },
    uploadProgress: function (load_status, load_session) {
      this.loadingDone = false;
      var sumLoading = 0;
      var allToLoad = 0;
      for (var i = 0; i < load_status.length; i++) {
        if (
          typeof load_status[i] === "undefined" ||
          typeof load_status[i]["total"] === "undefined"
        ) {
          continue;
        }
        allToLoad += load_status[i]["total"];
        sumLoading += load_status[i]["loaded"];
      }

      var percentage = sumLoading / allToLoad;
      console.log(percentage);
      if (percentage > 0.8) {
        NProgress.set(0.6);
        // setTimeout(this.keepProgressbarBusy.bind(this), 1100);
      } else {
        NProgress.set(percentage);
      }
    },
  },
  mounted() {
    this.fileServiceBaseUrl = mustGetEnv("VUE_APP_FILESERVICE_BASEURL");
    this.stl_viewer = new StlViewer(document.getElementById("stl_cont"), {
      all_loaded_callback: this.uploadDone.bind(this),
      loading_progress_callback: this.uploadProgress.bind(this),
      center_models: false,
      auto_rotate: true,
      controls: 1,
      allow_drag_and_drop: false,
    });

    if (this.jaw) {
      this.stl_viewer.add_model({
        id: 2,
        temp_filename: "mesh1.obj",
        filename: this.fileServiceBaseUrl + "/files/" + this.jaw,
        color: "#FFDDFF",
      });
    }

    // this.stl_viewer.add_model({
    //   id: 1,
    //   temp_filename: this.jaw.viewable_name,
    //   filename: this.jaw.url,
    //   color: "#FFDDFF",
    // });
  },
};
</script>
